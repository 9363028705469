import SimpleBar from 'simplebar';
import { mainApi } from '../../api';
import { setCountOfProductsBasket } from '../../utils/basket';
import { getServerErrors } from '../../utils/validate';

import 'simplebar/dist/simplebar.css';

/* eslint-disable max-statements */
const headerUI = document.querySelector('[data-header-ui]');
if (headerUI) {
  const roleAuthorized = document.querySelector('[data-role-authorized]');
  const fixedHeaderUI = headerUI.querySelector('[data-fixed-header-ui]');

  // При изменении стилей в боди - корректируем отступ для фиксированной шапки
  const body = document.querySelector('body');
  const bodyObserver = new MutationObserver((mutations) => {
    mutations.forEach(() => {
      const { paddingRight } = getComputedStyle(body);
      fixedHeaderUI.style.setProperty('padding-right', paddingRight);
    });
  });
  bodyObserver.observe(body, { attributeFilter: ['style'] });

  const btnChatBot = document.querySelectorAll('[data-btn-chat-bot-ui]');
  if (btnChatBot && btnChatBot.length) {
    btnChatBot.forEach((item) => {
      item.addEventListener('click', (event) => {
        event.stopPropagation();

        const chatBot = item.closest('[data-chat-bot-ui]');
        const contentChatBot = chatBot.querySelector('[data-content-chat-bot-ui]');

        if (contentChatBot) {
          contentChatBot.classList.add('open-chat-bot');
        }
      });
    });

    // При закрытии попапа - закрываем меню чат-бота
    document.addEventListener('close-popup', () => {
      const contentChatBot = document.querySelectorAll('[data-content-chat-bot-ui].open-chat-bot');
      if (contentChatBot && contentChatBot.length) {
        contentChatBot.forEach((el) => {
          el.classList.remove('open-chat-bot');
        });
      }
    });
  }

  const btnPersonalMenu = document.querySelectorAll('[data-btn-personal-menu-ui]');
  if (btnPersonalMenu && btnPersonalMenu.length) {
    btnPersonalMenu.forEach((item) => {
      const contentPersonalMenu = document.querySelector('[data-content-personal-menu-ui]');

      item.addEventListener('click', (event) => {
        event.stopPropagation();

        if (contentPersonalMenu.classList.contains('open')) {
          contentPersonalMenu.classList.remove('open');
        } else {
          contentPersonalMenu.classList.add('open');

          // Добавляем скролл внутри меню
          const scrollMenuHeader = contentPersonalMenu.querySelector('[data-scroll-menu-header-ui]');
          if (scrollMenuHeader) {
            new SimpleBar(scrollMenuHeader, { autoHide: false });
          }

          if (headerUI.getAttribute('data-dir-ui') === 'consumer'
            && !!roleAuthorized.getAttribute('data-role-authorized')) {
            initInfoConsumerPersonalMenu();
          }
        }
      });
    });

    // При закрытии попапа - закрываем меню пользователя
    document.addEventListener('close-popup', () => {
      const contentPersonalMenu = document.querySelector('[data-content-personal-menu-ui].open');
      if (contentPersonalMenu) {
        contentPersonalMenu.classList.remove('open');
      }
    });

    // При клике за пределами блока - закрываем его
    document.addEventListener('click', (e) => {
      const { target } = e;
      const contentPersonalMenu = document.querySelector('[data-content-personal-menu-ui].open');

      if (contentPersonalMenu) {
        const isPersonalMenu = target === contentPersonalMenu || contentPersonalMenu.contains(target);

        if (!isPersonalMenu) {
          contentPersonalMenu.classList.remove('open');
        }
      }
    });
  }

  const logoutAuth = document.querySelectorAll('[data-logout-ui]');
  if (logoutAuth && logoutAuth.length) {
    const numberTries = {};

    const onLogout = async (index) => {
      try {
        const response = await mainApi.modules.authorization.userLogout();
        if (response) {
          window.location.reload();
        }
      } catch (err) {
        numberTries[index] += 1;
        const serverErrors = getServerErrors(err);

        // При ошибке токена отправляем форму ещё раз - 3 попытки
        if (serverErrors && serverErrors.token && numberTries[index] < 3) {
          await onLogout(index);
        }
        if (serverErrors && serverErrors.token && numberTries[index] >= 3) {
          numberTries[index] = 0;
        }
      }
    };

    logoutAuth.forEach((item, index) => {
      item.addEventListener('click', async (e) => {
        e.preventDefault();
        e.stopPropagation();

        numberTries[index] = 0;
        await onLogout(index);
      });
    });
  }

  // Устанавливаем количество товаров в корзине
  const btnCountOfProductsBasket = document.querySelectorAll('[data-count-products-basket]');
  if (btnCountOfProductsBasket && btnCountOfProductsBasket.length) {
    mainApi.modules.basket.getBasket().then((response) => {
      setCountOfProductsBasket(btnCountOfProductsBasket, response);
    });
  }

  const initInfoConsumerPersonalMenu = () => {
    const ordersPersonalMenu = document.querySelector('[data-count-orders]');
    if (ordersPersonalMenu && !ordersPersonalMenu.classList.contains('init')) {
      const countOrdersPersonalMenu = ordersPersonalMenu.querySelector('[data-count-personal-menu-header]');

      if (countOrdersPersonalMenu) {
        mainApi.modules.order.getMonoOrders()
          .then((response) => {
            const filteredResponse = response.filter((el) => el.status !== 'F' && el.status !== 'R');

            if (filteredResponse && filteredResponse.length) {
              countOrdersPersonalMenu.innerHTML = filteredResponse.length;
              countOrdersPersonalMenu.classList.add('active');
            }
            ordersPersonalMenu.classList.add('init');
          });
      }
    }
  };
}
